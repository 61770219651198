<template>
  <v-dialog
    :value="isOpened"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    scrollable
  >
    <v-card id="filterDialog">
      <v-card-title class="pa-0">
        <v-toolbar
          dark
          color="primary"
          dense
        >
          <v-toolbar-title>Filtro avançado de produtos</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click="() => $emit('closeAdvancedFilter')"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
      </v-card-title>
      <v-card-text class="pt-10">
        <ProductsQuery
          v-if="isOpened"
          @cardClicked="addToCart"
        />
      </v-card-text>
    </v-card>
    <v-btn
      color="primary"
      fab
      fixed
      bottom
      right
      @click="() => (cartDrawer = !cartDrawer)"
      style="z-index: 99"
    >
      <v-badge
        :content="cartProducts.length"
        :value="cartProducts.length"
        color="accent"
        bordered
      >
        <v-icon>{{ cartDrawer ? 'mdi-close' : 'mdi-cart' }}</v-icon>
      </v-badge>
    </v-btn>

    <CartDrawer
      @clearCart="clearCart"
      @removeFromCart="removeFromCart"
      @addCartProductsToBudget="addCartProductsToBudget"
      :isDrawerOpened="cartDrawer"
      :cartProducts="cartProducts"
    />
  </v-dialog>
</template>
<script>
export default {
  props: {
    isOpened: { type: Boolean, default: () => false },
  },
  components: {
    ProductsQuery: () =>
      import('@/components/comercial/products-query/ProductsQuery'),
    CartDrawer: () => import('./ProductsAdvancedFilterModalCartDrawer.vue'),
  },
  data: () => ({
    cartDrawer: false,
    cartProducts: [],
  }),
  methods: {
    addToCart(product, selectedTable, rows, selectedPrint) {
      this.cartProducts.push({ ...product, selectedTable, rows, selectedPrint })
      this.$store.commit('setCartProductsIds', product.product_id)
    },
    removeFromCart(item) {
      let idx = this.cartProducts.indexOf(item)
      this.cartProducts.splice(idx, 1)
      this.$store.commit('removeCartProductsId', item.product_id)
      console.log(this.$store.state.cartProductsIds)
    },
    clearCart() {
      this.cartProducts = []
      this.cartDrawer = false
    },
    addCartProductsToBudget() {
      this.$emit('addCartProductsToBudget', this.cartProducts)
      this.clearCart()
    },
  },
}
</script>
